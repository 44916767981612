<template>
    <div>
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
            max-width="100%"
        >
            <v-card class="gradient--background">
                <v-toolbar
                    color="primary"
                    dark
                    dense
                    tile
                    max-height="48px"
                >
                    <v-btn
                        @click="dialog = false"
                        icon>
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title class="mx-auto" style="width:960px;">
                        Bestilling
                    </v-toolbar-title>
                    <v-spacer/>
                </v-toolbar>
                <v-card-text class="pt-6 mx-auto" style="  max-width:960px">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                label="Domæne"
                                readonly
                                :value="currentOrder.domain.split(' ')[0]"
                            />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                label="Pris pr. artikel"
                                :value="currentOrder.price"
                                readonly
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <div v-html="domainNotes" class="text--primary" style="padding:10px 8px;border-radius: 4px;margin-bottom: 24px;border:1px solid rgba(255,255,255,0.65)"></div>


                    <v-btn
                        @click="addArticleToCurrentOrder()"
                        color="green accent-3"
                        class="mb-4 mt-4"
                        outlined
                        dark
                        width="100%"
                    >
                        <v-icon left>
                            mdi-plus
                        </v-icon>
                        artikel
                    </v-btn>
                    <v-data-table
                        :items="currentOrder.articles"
                        :headers="headers"
                        hide-default-footer
                        :items-per-page="-1"
                        style="border: 1px solid #cecece !important;"
                        class="transparent"
                    >
                        <template v-slot:item.index="{item,index}">
                            #{{index + 1}}
                        </template>

                        <template v-slot:item.name="{item}">
                            <v-text-field
                                v-model="item.name"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </template>

                        <template v-slot:item.anchor="{item}">
                            <v-text-field
                                v-model="item.anchor"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </template>

                        <template v-slot:item.url="{item}">
                            <v-text-field
                                v-model="item.url"
                                outlined
                                dense
                                hide-details
                            ></v-text-field>
                        </template>

                        <template v-slot:item.publishDate="{item}">
                            <v-text-field
                                :value="new Date().daysFromNow(14)"
                                v-model="item.publishDate"
                                readonly
                            >
                                <template v-slot:prepend-inner>
                                    <date-time-picker
                                        :dateOnly="true"
                                        :min="new Date().daysFromNow(14)"
                                        :preSelected="new Date().daysFromNow(14)"
                                        @chosenDate="item.publishDate = $event"
                                    />
                                </template>
                            </v-text-field>
                        </template>

                        <template v-slot:item.remove="{item,index}">
                            <v-btn
                                icon
                                @click="removeArticleFromCurrentOrder(index)"
                            >
                                <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>


                    <v-divider class="mt-5 mb-2"/>
                    <v-card-actions class="elevation-0" style="width:100%; max-width:100%;">
                        <v-row>
                            <v-col cols="6" sm="6" md="6" xl="6" lg="6">
                                <span class="font-weight-bold">Samlet pris:&nbsp;</span>
                                <span v-html="currentOrder.articles.length * currentOrder.price + ',-'"></span>
                            </v-col>
                            <v-col
                                class="d-flex justify-end"
                            >
                                <v-btn
                                    color="white"
                                    outlined
                                    @click="dialog = false"
                                    class="mr-4"
                                >
                                    Annuller
                                </v-btn>
                                <v-btn
                                    :loading="orderLoad"
                                    color="primary"
                                    @click="sendOrderToCart()"
                                >
                                    <v-icon left small>mdi-cart</v-icon>
                                    Indgiv bestilling
                                </v-btn>
                            </v-col>
                        </v-row>


                    </v-card-actions>
                    <v-divider class="mt-2 mb-5"/>
                    <p>* Visse sider tillader ikke man forfatter eget artikelnavn.<br>
                        ** I visse tilfælde kan det have uønsket effekt at bruge fokusord som ankertekster. Vi vil derfor tage kontakt, hvis vi mener ankertekster bør tilrettes ift. linkprofilen for din hjemmeside.</p>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-btn
            color="primary"
            dark
            x-small
            @click="newOrder()"
        >
            <v-icon left x-small>mdi-cart</v-icon>
            Bestil
        </v-btn>
    </div>

</template>

<script>
    import CartOrderDialog from "./cartOrderDialog";
    import DateTimePicker from "../dateTimePicker/dateTimePicker";
    import {randomBytes} from 'crypto'

    Date.prototype.daysFromNow = function (days) {
        let fn = new Date();
        fn.setDate(fn.getDate() + days);
        let month = (fn.getMonth() + 1).toString().length === 1 ? '0' + (fn.getMonth() + 1) : (fn.getMonth() + 1)
        let day = fn.getDate().toString().length === 1 ? '0' + fn.getDate() : fn.getDate()
        return fn.getFullYear() + '-' + month + '-' + day
    }

    export default {
        name: "addToCartButton",
        props: ['orderItem'],
        components: {DateTimePicker, CartOrderDialog},
        data() {
            return {
                dialog: false,
                orderLoad: false,
                currentOrder: {
                    domain: '',
                    articles: [{name: '', anchor: '', url: '', publishDate: ''}]
                },
                headers: [
                    {text: '', value: 'index', width: '20px', sortable: false},
                    {text: 'Ønsket overskrift *', value: 'name'},
                    {text: 'Ønsket ankertekst **', value: 'anchor'},
                    {text: 'Linkadresse', value: 'url'},
                    {text: 'Ønsket udgivelsesdato', value: 'publishDate'},
                    {text: '', value: 'remove'},
                ],

            }
        },
        methods: {
            removeArticleFromCurrentOrder(articleId) {
                this.currentOrder.articles.length > 1 && this.currentOrder.articles.splice(articleId, 1)
            },
            addArticleToOrder() {
                this.currentOrder.articles.push({name: '', anchor: '', url: '', publishDate: '', articleId: randomBytes(16).toString('hex')})
            },
            setArticleDate(articleId, newDate) {
                this.currentOrder.articles[articleId].publishDate = newDate
            },
            addArticleToCurrentOrder() {
                this.currentOrder.articles.push({name: '', anchor: '', url: '', publishDate: '', articleId: randomBytes(16).toString('hex')})
            },
            newOrder() {
                this.dialog = true
                let newOrder = Object.assign({}, this.orderItem)
                newOrder.articles = [{name: '', anchor: '', url: '', publishDate: new Date().daysFromNow(14), articleId: randomBytes(16).toString('hex')}]
                newOrder.orderId = randomBytes(16).toString('hex')
                this.currentOrder = newOrder
            },
            sendOrderToCart() {
                this.orderLoad = true
                setTimeout(() => {
                    this.orderLoad = false
                    this.dialog = false
                    this.$store.dispatch('cartModule/addToCart', this.currentOrder)
                }, 225)
            },
        },
        mounted() {
            this.addArticleToOrder()
        },
        computed: {
            domainNotes: function () {
                let order = Object.assign({}, this.currentOrder)
                let parts = order.domain.split('Info: ')
                let notes = order.note + (parts.length > 1 ? '<br>' + parts[1] : '')
                return notes.trim().length === 0 ? 'Ingen noter' : notes
            }
        }
        ,
    }
</script>

<style scoped>

</style>