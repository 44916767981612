<template>
    <v-container class="col-12 pt-12">
        <v-row>
            <v-col>
                <v-card class="glass--bg">
                    <v-card-title>Todo</v-card-title>
                    <v-card-text>
                        <ol>
                            <li v-for="(todo,idx) in todos" :key="idx">
                                {{todo.title}}
                                <ol v-if="todo.sub">
                                    <li v-for="(subTodo,i) in todo.sub" v-html="subTodo.title"></li>
                                </ol>
                            </li>
                        </ol>
                    </v-card-text>
                </v-card>
            </v-col>
            <div class="col-12">
                <site-overview/>
            </div>
        </v-row>
    </v-container>
</template>
<script>
    import api from "../../services/api";
    import SiteOverview from "../../components/sites/siteOverview";
    import UserLog from "../../components/admin/userLog";

    export default {
        name: 'SiteList',
        components: {UserLog, SiteOverview},
        data() {
            return {
                todos: [
                    {
                        title: 'Bestillingsoversigt',
                        sub: [
                            {title: 'Gennemgå ordre'}
                        ]
                    },
                    {
                        title: 'Auto-mails (ordrebekræftelse mv.)',
                        sub: [
                            {title: 'Ordre udført'},
                        ]
                    },
                    {title: 'Tildel gennemgang'},
                    {title: 'Bedre system til ordreudførsel'},
                    {title: 'Lav det sidste på loginsiden'},
                    {title: 'Velkomsttekst på forside (logget ind)'},
                    {
                        title: 'Oversigtsforbedringer',
                        sub: [
                            {title: 'Hover på headers'},
                        ]
                    },
                    {title: 'Domæner skal genindlæses og priser skal ordnes'},
                    {title: '"Dine bestillinger" skal udvides'},
                    {title: 'Indstillinger skal udvides, hvis der er nogen'},
                    {title: 'Kurv skal gemmes i DB, så man har den uanset computer'},

                ]
            }
        },
        methods: {},
        computed: {
            prettify: function () {
                return JSON.stringify(this.json, null, 2)
            },
        }
    }
</script>
