<template>
    <v-row>
        <v-col xl="2" lg="2" md="12" sm="12" xs="12">
            <v-text-field
                @keydown="options.page = 1"
                hide-details
                dense
                outlined
                label="Søg.."
                v-model="options.search"
                :prepend-inner-icon="loading ? 'mdi-loading mdi-spin' :'mdi-magnify'"
                :append-icon="options.search.trim().length >= 1 ? 'mdi-close':''"
                @click:append="options.search = ''"
            />
            <v-divider class="mt-8 mb-8"/>
            <v-expansion-panels class="glass--bg">
                <v-expansion-panel class="transparent">
                    <v-expansion-panel-header class="transparent">Filtre</v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-6">
                        <label>Pris</label>
                        <v-range-slider
                            :hint="lowPrice + ' til ' + highPrice"
                            :value="price"
                            @change="setPrice()"
                            dense
                            persistent-hint
                            v-model="price"
                        />
                        <br>
                        <label>Kategori</label>
                        <v-combobox
                            :items="comboCategories"
                            v-model="selectedCategories"
                            small-chips
                            clearable
                            multiple
                            outlined
                            dense
                            hide-details
                        >
                            <template v-slot:selection="{ attrs, item, select, selected }">
                                <v-chip
                                    v-bind="attrs"
                                    :input-value="selected"
                                    small
                                    @click="select"
                                    @click:close="remove(item)"
                                    class="mb-1"
                                >
                                    <strong>{{ item }}</strong>&nbsp;
                                </v-chip>
                            </template>
                        </v-combobox>
                        <v-divider class="mt-4 mb-4"/>
                        <v-checkbox
                            label="Kasino tilladt"
                            v-model="options.casino"
                            dense
                        ></v-checkbox>
                        <v-checkbox
                            label="Lån tilladt"
                            v-model="options.loans"
                            dense
                        ></v-checkbox>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <v-col class="transparent" xl="10" lg="10" md="12" sm="12" xs="12">
            <v-card
                class="glass--bg"
            >
                <v-toolbar dense elevation="0" class="transparent">
                    <table-view/>
                    <v-toolbar-title>
                        Pressesider
                    </v-toolbar-title>
                </v-toolbar>
                <v-data-table

                    :footer-props="{
                        itemsPerPageOptions:[20, 40, 60],
                        showFirstLastPage: true,
                        firstIcon: 'mdi-chevron-double-left',
                        lastIcon: 'mdi-chevron-double-right',
                           'items-per-page-text':'Domæner'
                      }"

                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    :options.sync="options"
                    :search="options.search"
                    :server-items-length="totalItems"
                    class="overview-table transparent"
                    sort-by="domain"
                    sort-desc
                    :mobile-breakpoint="$store.state.dataTables.breakPoint"
                    dense
                >

                    <!-- HEADERS -->
                    <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
                        <v-tooltip bottom v-if="h.tip">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{h.text}}</span>
                            </template>
                            <span>{{h.tip}}</span>
                        </v-tooltip>
                        <span v-else>{{h.text}}</span>
                    </template>

                    <!-- BUY BUTTON -->
                    <template v-slot:item.order="{item}">
                        <add-to-cart-button :orderItem="item"/>
                    </template>
                    <template v-slot:item.note="{item}">
                            <span
                                style="white-space: nowrap;text-overflow: ellipsis;overflow:hidden;">{{item.note}}</span>
                    </template>

                    <!-- DOMAIN NAME -->
                    <template v-slot:item.domain="{item}">
                        <v-tooltip bottom v-if="item.note.trim().length >= 1">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">{{item.domain.split(' Info:')[0]}}*</span>
                            </template>
                            <span v-html="domainNotes(item)"></span>
                        </v-tooltip>
                        <span v-else>{{item.domain}}</span>
                    </template>

                    <!-- CATEGORIES -->
                    <template v-slot:item.categories="{item}">
                            <span v-if="item.categories.split(',').length === 1" style="white-space: nowrap">
                                {{categoryNameById(item.categories)}}
                            </span>
                        <v-tooltip bottom v-else>
                            <template v-slot:activator="{ on }">
                                <span v-on="on" v-if="(item.categories.split(',').length > 1)"
                                      style="white-space: nowrap">{{categoryNameById(item.categories.split(',').sort()[0])}}...</span>
                            </template>
                            <span>{{categoryNameById(item.categories)}}</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:item.price="{item}">
                        {{item.price.toLocaleString('da-DK')}}
                    </template>

                    <template v-slot:item.topics="{item}">
                        <v-icon v-if="item.casino" >mdi-cards-spade</v-icon>
                        <v-icon v-if="item.loans">mdi-cash-multiple</v-icon>
                    </template>
                    <template v-slot:item.createdDate="{item}">
                        {{formatDate(item.createdDate,'da-DK')}}
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import api from "@/services/api";
    import TableView from "../tableView";
    import AddToCartButton from "../cart/addToCartButton";


    export default {
        name: "siteOverview",
        components: {AddToCartButton, TableView},
        data: () => ({
            headers: [
                {text: "order", value: "order", width: '90px', sortable: false},
                {text: "Domæne", value: "domain"},
                {text: "DR", value: "domainRating", tip: 'Domain Rating', width: '68px'},
                {text: "RD", value: "referringDomains", width: '68px'},
                {text: "Kategori", value: "categories", width: '130px', sortable: false},
                {text: "Pris", value: "price", width: '88px'},
                {text: "Emner", value: "topics", width: '88px', sortable: false},
                {text: "Udbyder", value: "provider", width: '98px'},
                {text: "Tekst", value: "textInclusive", width: '160px'},
                {text: "Tilføjet d.", value: "createdDate", width: '140px'},
            ],
            items: [],
            totalItems: 0,
            loading: false,
            options: {
                search: '',
                price: [],
                categories: [],
                casino: false,
                loans: false,
            },
            price: [0, 100],
            priceRange: [0, 0],
            categories: [],
            selectedCategories: [],
        }),
        methods: {
            getSites() {
                this.loading = true
                api.get('/sites', {
                    params: this.options
                })
                    .then(response => {
                        this.items = response.data.items
                        this.totalItems = response.data.totalItems
                        this.priceRange = [response.data.price.low, response.data.price.high]
                        this.loading = false
                    })
            },
            getCategories() {
                api.get('categories')
                    .then(response => {
                        this.categories = response.data
                    })
            },
            categoryNameById(id) {
                let categories = []
                let category
                id.split(',').forEach((cid, i) => {
                    category = this.categories.find(x => x.id == cid)
                    typeof category !== 'undefined' ? categories.push(category.name) : ''
                })
                return categories.join(', ')

            },
            setPrice() {
                this.options.price = [this.lowPrice, this.highPrice]
            },
            randomColor() {
                return (function lol(m, s, c) {
                    return s[m.floor(m.random() * s.length)] +
                        (c && lol(m, s, c - 1));
                })(Math, '3456789ABCDEF', 6)
            },
            remove(item) {
                this.options.categories.splice(this.options.categories.indexOf(item), 1)
                this.options.categories = [...this.options.categories]
            },
            updateCategoryFilter() {
                let allCategories = this.categories
                this.options.categories = this.selectedCategories.map(function (e) {
                    return allCategories.find(x => x.name === e) ? allCategories.find(x => x.name === e).id : ''
                })
            },
            domainNotes(item) {
                let parts = item.domain.split('Info: ')
                return item.note + (parts.length > 1 ? '<br>\r\n' + parts[1] : '')
            },
            formatDate(dateString) {
                const [Y, m, d] = dateString.split(' ')[0].split('-')
                return `${d}-${m}-${Y}`
            },
            newOrder(item) {
                this.currentOrder = item
            }
        },
        mounted() {
            this.getCategories()
        },
        computed: {
            lowPrice: function () {
                return ((this.priceRange[1] / 100 * this.price[0]) + this.priceRange[0]).toFixed(0)
            },
            highPrice: function () {
                return (this.priceRange[1] / 100 * this.price[1]).toFixed(0)
            },
            comboCategories: function () {
                return this.categories.map(e => {
                    return e.name
                }).sort()
            },
        },
        watch: {
            options: {
                handler() {
                    this.getSites()
                },
                deep: true,
            },
            '$route'(to, from) {
            },
            selectedCategories: {
                handler() {
                    this.updateCategoryFilter()
                }
            }
        },
    }
</script>

<style>
    /*
    .overview-table thead:first-of-type th {
        background-color: #311b92 !important;
        height: 48px !important;
    }

    .overview-table thead:first-of-type .v-data-table-header__icon {
        color: #fff !important;
    }

    .overview-table thead:first-of-type th span {
        color: #fff;
    }

    .overview-table thead:first-of-type th:first-child {
        border-top-left-radius: 4px;
    }

    .overview-table thead:first-of-type th:last-child {
        border-top-right-radius: 4px;
    }
    
     */
</style>