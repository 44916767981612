<template>
    <v-dialog
        v-model="$store.state.cartModule.orderDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        max-width="100%"
    >
        <v-card>
            <v-toolbar
                color="primary"
                dark
                max-height="68"
                tile
            >
                <v-btn
                    @click="$store.state.cartModule.orderDialog = false"
                    icon>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-toolbar-title>
                    Bestilling
                </v-toolbar-title>
                <v-spacer/>
                <v-toolbar-items></v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pt-6 mx-auto" style="  max-width:960px">
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                            label="Domæne"
                            readonly
                            :value="currentOrder.domain.split(' ')[0]"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            label="Pris pr. artikel"
                            :value="currentOrder.price"
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div
                    v-html="domainNotes"
                    style="background:#f1f1f1;padding:10px 8px;border-radius: 4px;margin-bottom: 24px"
                ></div>
                <v-simple-table
                    class="articleOrderList"
                >
                    <table>
                        <thead>
                        <tr>
                            <td v-for="header in headers" class="pl-2"> {{header.text}}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(article,index) in currentOrder.articles"
                        >
                            <td class="pa-2">
                                <v-text-field
                                    dense
                                    hide-details
                                    background-color="white"
                                    solo
                                ></v-text-field>
                            </td>
                            <td class="pa-2">
                                <v-text-field
                                    dense
                                    hide-details
                                    background-color="white"
                                    solo
                                ></v-text-field>
                            </td>
                            <td class="pa-2">
                                <v-text-field
                                    dense
                                    hide-details
                                    background-color="white"
                                    solo
                                ></v-text-field>
                            </td>
                            <td class="pa-2">
                                <v-text-field
                                    dense
                                    hide-details
                                    :value="daysFromNow(14)"
                                    v-model="currentOrder.articles[index].publishDate"
                                    readonly
                                    solo
                                >
                                    <template v-slot:prepend-inner>
                                        <date-time-picker
                                            :dateOnly="true"
                                            :min="daysFromNow(14)"
                                            :preSelected="daysFromNow(14)"
                                            @chosenDate="currentOrder.articles[index].publishDate = $event"
                                        />
                                    </template>
                                </v-text-field>
                            </td>
                            <td>
                                <v-btn
                                    icon
                                    @click="removeArticleFromCurrentOrder(index)"
                                >
                                    <v-icon>mdi-trash-can</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </v-simple-table>
            </v-card-text>

            <v-card-actions class="pb-5 mx-auto" style=" width:960px; max-width:100%;">
                <v-btn @click="addArticleToCurrentOrder()">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    1 artikel
                </v-btn>
                <v-spacer/>
                <v-btn
                    color="red"
                    text
                    @click="$store.state.cartModule.orderDialog = false"
                >
                    Annuller
                </v-btn>
                <v-btn color="primary" @click="$store.dispatch('cartModule/addToCart',currentOrder)">
                    <v-icon left small>mdi-cart</v-icon>
                    Bestil
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import DateTimePicker from "../dateTimePicker/dateTimePicker";

    export default {
        name: "cartOrderDialog",
        components: {DateTimePicker},
        props: ['orderItem'],
        data() {
            return {
                currentOrder: {
                    domain: '',
                    articles: [{}]
                },
                headers: [
                    {text: 'Ønsket artikelnavn', value: 'name'},
                    {text: 'Ankertekst', value: 'anchor'},
                    {text: 'Linkadresse', value: 'url'},
                    {text: 'Ønsket udgivelsesdato', value: 'publishDate'},
                    {text: '', value: 'remove'},
                ],

            }
        },
        methods: {
            removeArticleFromCurrentOrder(articleId) {
                this.currentOrder.articles.splice(articleId, 1)
            },
            addArticleToOrder() {
                this.currentOrder.amount++
                this.currentOrder.articles.push({})
            },
            setArticleDate(articleId, newDate) {
                this.currentOrder.articles[articleId].publishDate = newDate
            },
            addArticleToCurrentOrder() {
                this.currentOrder.articles.push({name: '', anchor: '', url: '', publishDate: ''})
            },
            prepareOrder() {
                this.currentOrder = payload
            }
        },
        mounted() {
            console.log(this.orderItem)
            this.currentOrder = Object.assign({}, this.orderItem)
            console.log(this.currentOrder)
        },
        computed: {
            domainNotes: function () {
                let order = Object.assign({}, this.currentOrder)
                let parts = order.domain.split('Info: ')
                return order.note + (parts.length > 1 ? '<br>' + parts[1] : '')
            }
        },
        watch: {
            '$store.state.cartModule.orderDialog': {
                immediate: true,
                handler() {
                    if (this.$store.state.cartModule.orderDialog) {

                        this.currentOrder = Object.assign({}, this.orderItem)
                        console.log(this.orderItem)
                    }
                }
            }
        }
    }
</script>

<style>
    .articleOrderList tbody tr td {
      /*  background: #f1f1f1 !important;*/
    }
</style>