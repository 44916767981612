var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":"","max-width":"100%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"gradient--background"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":"","tile":"","max-height":"48px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"mx-auto",staticStyle:{"width":"960px"}},[_vm._v(" Bestilling ")]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-6 mx-auto",staticStyle:{"max-width":"960px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Domæne","readonly":"","value":_vm.currentOrder.domain.split(' ')[0]}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Pris pr. artikel","value":_vm.currentOrder.price,"readonly":""}})],1)],1),_c('div',{staticClass:"text--primary",staticStyle:{"padding":"10px 8px","border-radius":"4px","margin-bottom":"24px","border":"1px solid rgba(255,255,255,0.65)"},domProps:{"innerHTML":_vm._s(_vm.domainNotes)}}),_c('v-btn',{staticClass:"mb-4 mt-4",attrs:{"color":"green accent-3","outlined":"","dark":"","width":"100%"},on:{"click":function($event){return _vm.addArticleToCurrentOrder()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" artikel ")],1),_c('v-data-table',{staticClass:"transparent",staticStyle:{"border":"1px solid #cecece !important"},attrs:{"items":_vm.currentOrder.articles,"headers":_vm.headers,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" #"+_vm._s(index + 1)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.anchor",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.anchor),callback:function ($$v) {_vm.$set(item, "anchor", $$v)},expression:"item.anchor"}})]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":""},model:{value:(item.url),callback:function ($$v) {_vm.$set(item, "url", $$v)},expression:"item.url"}})]}},{key:"item.publishDate",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"value":new Date().daysFromNow(14),"readonly":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('date-time-picker',{attrs:{"dateOnly":true,"min":new Date().daysFromNow(14),"preSelected":new Date().daysFromNow(14)},on:{"chosenDate":function($event){item.publishDate = $event}}})]},proxy:true}],null,true),model:{value:(item.publishDate),callback:function ($$v) {_vm.$set(item, "publishDate", $$v)},expression:"item.publishDate"}})]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeArticleFromCurrentOrder(index)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}])}),_c('v-divider',{staticClass:"mt-5 mb-2"}),_c('v-card-actions',{staticClass:"elevation-0",staticStyle:{"width":"100%","max-width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6","xl":"6","lg":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Samlet pris: ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.currentOrder.articles.length * _vm.currentOrder.price + ',-')}})]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"white","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Annuller ")]),_c('v-btn',{attrs:{"loading":_vm.orderLoad,"color":"primary"},on:{"click":function($event){return _vm.sendOrderToCart()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-cart")]),_vm._v(" Indgiv bestilling ")],1)],1)],1)],1),_c('v-divider',{staticClass:"mt-2 mb-5"}),_c('p',[_vm._v("* Visse sider tillader ikke man forfatter eget artikelnavn."),_c('br'),_vm._v(" ** I visse tilfælde kan det have uønsket effekt at bruge fokusord som ankertekster. Vi vil derfor tage kontakt, hvis vi mener ankertekster bør tilrettes ift. linkprofilen for din hjemmeside.")])],1)],1)],1),_c('v-btn',{attrs:{"color":"primary","dark":"","x-small":""},on:{"click":function($event){return _vm.newOrder()}}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("mdi-cart")]),_vm._v(" Bestil ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }