var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"xl":"2","lg":"2","md":"12","sm":"12","xs":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","label":"Søg..","prepend-inner-icon":_vm.loading ? 'mdi-loading mdi-spin' :'mdi-magnify',"append-icon":_vm.options.search.trim().length >= 1 ? 'mdi-close':''},on:{"keydown":function($event){_vm.options.page = 1},"click:append":function($event){_vm.options.search = ''}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('v-divider',{staticClass:"mt-8 mb-8"}),_c('v-expansion-panels',{staticClass:"glass--bg"},[_c('v-expansion-panel',{staticClass:"transparent"},[_c('v-expansion-panel-header',{staticClass:"transparent"},[_vm._v("Filtre")]),_c('v-expansion-panel-content',{staticClass:"pt-6"},[_c('label',[_vm._v("Pris")]),_c('v-range-slider',{attrs:{"hint":_vm.lowPrice + ' til ' + _vm.highPrice,"value":_vm.price,"dense":"","persistent-hint":""},on:{"change":function($event){return _vm.setPrice()}},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('br'),_c('label',[_vm._v("Kategori")]),_c('v-combobox',{attrs:{"items":_vm.comboCategories,"small-chips":"","clearable":"","multiple":"","outlined":"","dense":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var select = ref.select;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"mb-1",attrs:{"input-value":selected,"small":""},on:{"click":select,"click:close":function($event){return _vm.remove(item)}}},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("  ")])]}}]),model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}}),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('v-checkbox',{attrs:{"label":"Kasino tilladt","dense":""},model:{value:(_vm.options.casino),callback:function ($$v) {_vm.$set(_vm.options, "casino", $$v)},expression:"options.casino"}}),_c('v-checkbox',{attrs:{"label":"Lån tilladt","dense":""},model:{value:(_vm.options.loans),callback:function ($$v) {_vm.$set(_vm.options, "loans", $$v)},expression:"options.loans"}})],1)],1)],1)],1),_c('v-col',{staticClass:"transparent",attrs:{"xl":"10","lg":"10","md":"12","sm":"12","xs":"12"}},[_c('v-card',{staticClass:"glass--bg"},[_c('v-toolbar',{staticClass:"transparent",attrs:{"dense":"","elevation":"0"}},[_c('table-view'),_c('v-toolbar-title',[_vm._v(" Pressesider ")])],1),_c('v-data-table',{staticClass:"overview-table transparent",attrs:{"footer-props":{
                    itemsPerPageOptions:[20, 40, 60],
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right',
                       'items-per-page-text':'Domæner'
                  },"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"search":_vm.options.search,"server-items-length":_vm.totalItems,"sort-by":"domain","sort-desc":"","mobile-breakpoint":_vm.$store.state.dataTables.breakPoint,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
                  var header = ref.header;
return [(h.tip)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.tip))])]):_c('span',[_vm._v(_vm._s(h.text))])]}}}),{key:"item.order",fn:function(ref){
                  var item = ref.item;
return [_c('add-to-cart-button',{attrs:{"orderItem":item}})]}},{key:"item.note",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"}},[_vm._v(_vm._s(item.note))])]}},{key:"item.domain",fn:function(ref){
                  var item = ref.item;
return [(item.note.trim().length >= 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.domain.split(' Info:')[0])+"*")])]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.domainNotes(item))}})]):_c('span',[_vm._v(_vm._s(item.domain))])]}},{key:"item.categories",fn:function(ref){
                  var item = ref.item;
return [(item.categories.split(',').length === 1)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.categoryNameById(item.categories))+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [((item.categories.split(',').length > 1))?_c('span',_vm._g({staticStyle:{"white-space":"nowrap"}},on),[_vm._v(_vm._s(_vm.categoryNameById(item.categories.split(',').sort()[0]))+"...")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.categoryNameById(item.categories)))])])]}},{key:"item.price",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toLocaleString('da-DK'))+" ")]}},{key:"item.topics",fn:function(ref){
                  var item = ref.item;
return [(item.casino)?_c('v-icon',[_vm._v("mdi-cards-spade")]):_vm._e(),(item.loans)?_c('v-icon',[_vm._v("mdi-cash-multiple")]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdDate,'da-DK'))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }